import React, { Component } from "react";
import BreadCrumb from "./BreadCrumb";
import EventDetailsWrapper from "./EventDetailsWrapper";
import Tik from "../../../../assets/images/risottata/Tik-idea.svg";


function Risottata(props) {

    return (
      <>
          <div className="hero-area">
            <div className="hero-shape">
              <img src={Tik} alt="HeroIMG" className="svg-shape" />
            </div>
          </div>
        <BreadCrumb />
        <EventDetailsWrapper />
      </>
    );
}

export default Risottata;

import React, { Component } from "react";
import HeroArea from "./HeroArea";
import PopularEventArea from "./PopularEventArea";

class HomePage extends Component {
  render() {
    return (
      <div className="homepage">
        <HeroArea />
        <PopularEventArea />
      </div>
    );
  }
}

export default HomePage;

import React, { Component } from "react";
import CountUp from "react-countup";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
import AboutVideo from "../../../assets/images/about/a-mini-video.png";
// mini gallary
import MiniGallary1 from "../../../assets/images/about/a-mini1.png";
import MiniGallary2 from "../../../assets/images/about/a-mini2.png";
// icon image
import Icon2 from "../../../assets/images/icons/event-sm.png";
import QuoteIcon from "../../../assets/images/icons/qoute-icon.png";
import Icon3 from "../../../assets/images/icons/sed-sm.png";
import Icon1 from "../../../assets/images/icons/speaker-sm.png";
// feature icon image import
import FeatureIcon3 from "../../../assets/images/icons/gaust-md.png";
import FeatureIcon2 from "../../../assets/images/icons/sound-md.png";
import FeatureIcon1 from "../../../assets/images/icons/speaker-md.png";
import VideoShapeIcon from "../../../assets/images/shapes/play-btn.png";

import GallaryIem3 from "../../../assets/images/gallary/e-gallary-l1.png";
import GallaryIem4 from "../../../assets/images/gallary/e-gallary-l2.png";
import GallaryIem2 from "../../../assets/images/gallary/e-gallary-sm1.png";
import GallaryIem1 from "../../../assets/images/gallary/e-gallary1.png";
// Gallary View image import
import GallaryView1 from "../../../assets/images/gallary/view1.png";
import GallaryView2 from "../../../assets/images/gallary/view2.png";
// reply button icon
import ReplyBtnIcon from "../../../assets/images/icons/reply-icon.png";
// commentor image import
import Commentor1 from "../../../assets/images/speaker/commentor-1.png";
import Commentor2 from "../../../assets/images/speaker/commentor-2.png";
import Commentor3 from "../../../assets/images/speaker/commentor-3.png";
// main speaker image import
import MainSeaker1 from "../../../assets/images/speaker/event-sm1.png";
import MainSeaker2 from "../../../assets/images/speaker/event-sm2.png";
import MainSeaker3 from "../../../assets/images/speaker/event-sm3.png";
import MainSeaker4 from "../../../assets/images/speaker/event-sm4.png";
import MainSeaker5 from "../../../assets/images/speaker/event-sm5.png";
import MainSeaker6 from "../../../assets/images/speaker/event-sm6.png";
// speaker image import
import speaker1 from "../../../assets/images/speaker/speaker-sm1.png";
import speaker2 from "../../../assets/images/speaker/speaker-sm2.png";
import { SRLWrapper } from "simple-react-lightbox";
import speaker3 from "../../../assets/images/speaker/speaker-sm3.png";
import speaker4 from "../../../assets/images/speaker/speaker-sm4.png";
// main sponser logo image import
import sponsorLogo1 from "../../../assets/images/sponsor/ed-sponsor1.png";
import sponsorLogo2 from "../../../assets/images/sponsor/ed-sponsor2.png";
import sponsorLogo3 from "../../../assets/images/sponsor/ed-sponsor3.png";
import sponsorLogo4 from "../../../assets/images/sponsor/ed-sponsor4.png";

class AboutWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isOpen: false,
    };
}
 
  componentDidMount() {
    // animation script
    new WOW.WOW().init();
  }
  render() {
    // modal video change state
    const { isOpen } = this.state;
    return (
      <>
        {/* =============== About wrapper start =============== */}
        
        <div className="about-wrapper mt-96">
          <div className="container">
            <div className="about-company">
              <h2>
                Kiwanis persone che si impegnano socialmente
              </h2>
              <div className="row">
                <div className="company-info">
                  <div className="col">
                    <p>
                    Kiwanis è un’organizzazione di servizio a livello mondiale,
                    internazionale e moderna di Club di servizio.
                    Il Kiwanis ha origine a Detroit (USA) ed è stato fondato il 21.01.1915.
                    Politicamente neutri, con valori fondamentali liberali e un obiettivo sociale,
                    così si definiscono i 600’000 membri in tutto il mondo.
                    </p>
                  </div>
                  <div className="col">
                    <p>
                    In Europa il primo Club è stato fondato a Vienna nel 1963.
                    Nello stesso anno nasceva il primo Club della Svizzera e più precisamente a Basilea.
                    Kiwanis Club Bellinzona e Valli è stato creato nel 1985 con la charter-night il 12 aprile 1986.
                    </p>
                  </div>
                  <div className="col">
                    <p>
                    In Svizzera ci son ben 200 Kiwanis Club con 7’500 membri.
                    Essi formano le forti e solide fondamenta della famiglia Kiwaniana.
                    In questi Club locali ben ancorati nel territorio si incontrano regolarmente proprietari di ditte, uomini d’affari in posizioni dirigenziali e liberi professionisti.
                    </p>
                  </div>
                  <div className="about-more-btn">
                    <Link
                      to={"https://kiwanis-bellinzona.ch/"}
                      target="_blank"
                      className="primary-btn-fill">
                      Visita il nostro sito
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-history position-relative mt-120">
              <h3 className="float-title position-absolute">Fai agli altri ciò che vorresti fosse fatto a te</h3>
              <div className="qoute-icon position-absolute">
              </div>
              <p>
              Essere membro di Kiwanis significa:
                <ul>
                  <li>• Rendere possibile un futuro migliore ai bambini del mondo;</li>
                  <li>• Costruire insieme, in modo solidale, un futuro migliore per la nostra società;</li>
                  <li>• Sostenere direttamente e indirettamente persone svantaggiate;</li>
                  <li>• Rispettare ogni individuo e le sue opinioni;</li>
                  <li>• Coltivare i valori umani e intellettuali;</li>
                  <li>• Vivere l’amicizia;</li>
                  <li>• Impegnarsi sempre per il bene comune.</li>
                </ul>
              </p>
            </div>
            <div className="ed-main-wrap">
              <div className="ed-tabs-wrapper">
                <div className="tab-content" id="pills-tabContent2">
                  <div className="details-tab-content">
                    <div className="row ed-overview">
                      <h5 className="ed-subtitle">Le 6 finalità e la regola d’oro del Kiwanis</h5>
                      <ul className="overview-list">
                        <li>
                          1. Affermare i valori umani e spirituali più importanti dei valori materiali.
                        </li>
                        <li>
                          2. Incoraggiare l’applicazione quotidiana della regola d’oro “Fai agli altri ciò vorresti fosse fatto a Te” in tutte le relazioni umane.
                        </li>
                        <li>
                          3. Promuovere l’adozione di norme sempre migliori nella vita sociale, economica e professionale.
                        </li>
                        <li>
                          4. Contribuire con l’esempio e il consiglio alla formazione di cittadini più tolleranti, più attivi e più disposti al servizio verso il prossimo.
                        </li>
                        <li>
                          5. Sviluppare, tramite i club kiwaniani, amicizie durature, rendere servizi disinteressati al prossimo e costruire comunità migliori.
                        </li>
                        <li>
                          6. Collaborare alla formazione d una sana mentalità in seno alla società in modo da promuovere l’onestà, la giustizia, la fedeltà a un Paese libero, come pure i buoni vincoli internazionali d’amicizia.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============== About wrapper end =============== */}
        <React.Fragment>
          <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="-tJYN-eG1zk" onClose={() => this.setState({ isOpen: false })} />
        </React.Fragment>
      </>
    );
  }
}

export default AboutWrapper;

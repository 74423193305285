import React, { Component } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../../assets/css/animate.css";
import BlogThumb from "../../../../assets/images/risottata/Locandina-annullato.jpg";
// import BlogThumb from "../../../../assets/images/event/ed-thumb.png";

import Gallery1 from "../../../../assets/images/risottata/Immagine_1.jpeg";
import Gallery2 from "../../../../assets/images/risottata/Immagine_2.jpg";
import Gallery3 from "../../../../assets/images/risottata/Immagine_3.jpg";
import Gallery4 from "../../../../assets/images/risottata/Immagine_4.jpg";
import Gallery5 from "../../../../assets/images/risottata/Immagine_5.jpg";
import Gallery6 from "../../../../assets/images/risottata/Immagine_6.jpg";
import Gallery7 from "../../../../assets/images/risottata/Immagine_7.jpg";
import Gallery8 from "../../../../assets/images/risottata/Immagine_8.jpg";
import Gallery9 from "../../../../assets/images/risottata/Immagine_9.jpg";
import Gallery10 from "../../../../assets/images/risottata/Immagine_10.jpg";
import Gallery11 from "../../../../assets/images/risottata/Immagine_11.jpg";


// Gallary View image import
import GallaryView1 from "../../../../assets/images/gallary/view1.png";
import GallaryView2 from "../../../../assets/images/gallary/view2.png";
// reply button icon
import ReplyBtnIcon from "../../../../assets/images/icons/reply-icon.png";
// commentor image import
import Commentor1 from "../../../../assets/images/risottata/Evento_1.jpg";
import Commentor2 from "../../../../assets/images/risottata/Evento_2.jpg";
import Commentor3 from "../../../../assets/images/risottata/Evento_3.jpg";
import Commentor4 from "../../../../assets/images/risottata/Evento_4.jpg";



// main speaker image import
// import MainSeaker1 from "../../../../assets/images/speaker/event-sm1.png";
import MainSeaker1 from "../../../../assets/images/risottata/logo-osteria-nando-bellinzona-pizzeria-sementina.png";

// import MainSeaker2 from "../../../../assets/images/speaker/event-sm2.png";
import MainSeaker2 from "../../../../assets/images/risottata/lampara-ristorante-pizzeria-bellinzona-logo.png";

import MainSeaker3 from "../../../../assets/images/risottata/LocandaMarco.jpg";
import MainSeaker4 from "../../../../assets/images/speaker/event-sm4.png";
import MainSeaker5 from "../../../../assets/images/speaker/event-sm5.png";
import MainSeaker6 from "../../../../assets/images/speaker/event-sm6.png";
// speaker image import
import speaker1 from "../../../../assets/images/speaker/speaker-sm1.png";
import speaker2 from "../../../../assets/images/speaker/speaker-sm2.png";
import { SRLWrapper } from "simple-react-lightbox";
import speaker3 from "../../../../assets/images/speaker/speaker-sm3.png";
import speaker4 from "../../../../assets/images/speaker/speaker-sm4.png";
// main sponser logo image import
import sponsorLogo1 from "../../../../assets/images/risottata/SPONSOR-Kiwanis.svg";
import sponsorLogo2 from "../../../../assets/images/risottata/SPONSOR-Lions.svg";
import sponsorLogo3 from "../../../../assets/images/risottata/SPONSOR-Rotary.svg";
import sponsorLogo4 from "../../../../assets/images/sponsor/ed-sponsor4.png";

import OrganizerIMG1 from "../../../../assets/images/risottata/Conconi.jpg";
import OrganizerIMG2 from "../../../../assets/images/risottata/MondiniSA.jpg";
import OrganizerIMG3 from "../../../../assets/images/risottata/axim.jpg";
import OrganizerIMG4 from "../../../../assets/images/risottata/Winteler.jpg";
import OrganizerIMG5 from "../../../../assets/images/risottata/Baccialegno.jpg";
import OrganizerIMG6 from "../../../../assets/images/risottata/QFort.jpg";
import OrganizerIMG7 from "../../../../assets/images/risottata/EventMore.jpg";



import PlanimetriaMattino from "../../../../assets/images/risottata/Planimetria_Mattina.jpg";
import PlanimetriaSera from "../../../../assets/images/risottata/Planimetria_Sera.jpg";





import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";

class   EventDetailsLeftArea extends Component {
  componentDidMount(){
    new WOW.WOW().init()
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const sponsorSlider={
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: 24,
      loop: true,
      roundLengths: true,
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: ".speaker-two-pagination",
      },
      breakpoints: {
        300:{
          slidesPerView: 1,
        },
        480:{
          slidesPerView: 1
        },
        768:{
          slidesPerView: 2
        },
        992:{ 
          slidesPerView: 2
        },
        1200:{
          slidesPerView: 3
        },
       
      }
    }
    return (
      <>


        <div className="col-xl-8">
          <div className="ed-main-wrap">
            <div className="ed-top">
              <div className="ed-thumb">
                <img src={BlogThumb} alt="Blog details" />
              </div>
            </div>

            <div className="ed-top">
              <div className="custom-container">
                <div className="event-info row align-items-center">
                  <div className="col-lg-3 col-md-4">
                  <div className="single-event-info">
                    <div className="info-icon">
                          <i className="bi bi-calendar-week" />
                        </div>
                        <div className="info-content">
                          <h5>Data inizio</h5>
                          <p>Settembre 2023</p>
                        </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="single-event-info">
                      <div className="info-icon">
                        <i className="bi bi-megaphone" />
                      </div>
                      <div className="info-content">
                        <h5>Data fine</h5>
                        <p>Settembre 2023</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="single-event-info">
                      <div className="info-icon">
                        <i className="bi bi-geo-alt" />
                      </div>
                      <div className="info-content">
                        <h5>Location</h5>
                        <p>Piazza del Sole<br></br>6500 Bellinzona</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4">
                    <div className="single-event-info">
                        <div className="info-icon">
                          <i className="bi bi-person" />
                        </div>
                        <div className="info-content">
                            <h5>Organizzatore</h5>
                            <p>Kiwanis Club <br></br>Bellinzona e Valli</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ed-top">
              <div className="custom-container">
                <div className="event-info row" >
                  <div className="row" >
                    <div className="custom-container-due" >
                      <div className="row justify-content-between"  >
                        <div className="event-organizer-wrap">
                          <div className="category-title">
                            <h4>SPONSOR DELL'EVENTO</h4>
                          </div>
                        </div>
                        <ul className="row justify-content-between">
                          <div className="event-organizer-wrap" >
                            <div className="organizer-info custom-container-img" >
                              {/* conconi */}
                                <img src={OrganizerIMG1}  alt="Imgs" className="logo-promoter logo-promoter-stile "/>
                            </div>
                          </div>
                          <Swiper {...sponsorSlider} className="swiper-wrapper">
                            <SwiperSlide className="swiper-slide">
                              <div className="event-organizer-wrap col-lg-3 col-md-3">
                                <div className="organizer-info">
                                  <div className="organizer-image">
                                    <img src={OrganizerIMG2}  alt="Imgs" className="logo-promoter logo-promoter-stile"/>
                                    </div>
                                </div>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                              <div className="event-organizer-wrap col-lg-3 col-md-3">
                                <div className="organizer-info">
                                  <div className="organizer-image">
                                    <img src={OrganizerIMG3}  alt="Imgs" className="logo-promoter logo-promoter-stile"/>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                              <div className="event-organizer-wrap col-lg-3 col-md-3">
                                <div className="organizer-info">
                                  <div className="organizer-image">
                                    <img src={OrganizerIMG4}  alt="Imgs" className="logo-promoter logo-promoter-stile"/>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                              <div className="event-organizer-wrap col-lg-3 col-md-3">
                                <div className="organizer-info">
                                  <div className="organizer-image">
                                    <img src={OrganizerIMG5}  alt="Imgs" className="logo-promoter logo-promoter-stile"/>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                              <div className="event-organizer-wrap col-lg-3 col-md-3">
                                <div className="organizer-info">
                                  <div className="organizer-image">
                                    <img src={OrganizerIMG6}  alt="Imgs" className="logo-promoter logo-promoter-stile"/>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide">
                              <div className="event-organizer-wrap col-lg-3 col-md-3">
                                <div className="organizer-info">
                                  <div className="organizer-image">
                                    <img src={OrganizerIMG7}  alt="Imgs" className="logo-promoter logo-promoter-stile"/>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

       



            <div className="ed-tabs-wrapper">
            <div className="tabs-pill">
                <ul
                  className="nav nav-pills justify-content-center"
                  id="pills-tab2"
                  role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-details-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-details"
                      type="button"
                      role="tab"
                      aria-controls="pills-details"
                      aria-selected="true">
                      <i className="bi bi-info-circle" /> <span >Dettagli</span>
                      {/* style={{ border: 'solid blue' }} */}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-schedule-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-schedule"
                      type="button"
                      role="tab"
                      aria-controls="pills-schedule"
                      aria-selected="false">
                      <i className="bi bi-calendar3" /> <span>Programma</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-comitato-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-comitato"
                      type="button"
                      role="tab"
                      aria-controls="pills-comitato"
                      aria-selected="false">
                      <i className="bi bi-people" /><span>Comitato</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-gallary-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-gallary"
                      type="button"
                      role="tab"
                      aria-controls="pills-gallary"
                      aria-selected="false">
                      <i className="bi bi-images" /> <span>Galleria</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent2">
                <div
                  className="tab-pane fade show active"
                  id="pills-details"
                  role="tabpanel"
                  aria-labelledby="pills-details-tab">
                  <div className="details-tab-content">
                    <p>
                    Il tradizionale “Risotto in piazza”, evento benefico organizzato annualmente dal Kiwanis Club Bellinzona e Valli (KCBeV), quest’anno è finalizzato alla raccolta fondi da devolvere alla Culla San Marco a Bellinzona, dove le suore accolgono donne e minori provenienti da situazioni familiari complesse, fornendo loro ospitalità e conforto. I fondi serviranno per la ristrutturazione e per permettere di avviare importanti ed urgenti lavori di manutenzione della sede. 
L’evento si terrà a Bellinzona nella suggestiva Piazza del Sole, sabato 27 maggio 2023.
                    </p>
              <div className="custom-container">
                <div className="event-info row">
                  <div className="row" >
                    <div className="custom-container-due" >
                      <div className="row justify-content-between" >
                        <div className="event-organizer-wrap">
                          <div className="category-title">
                            <h4>PLANIMETRIA MATTINO</h4>
                            <h5>Clicca per ingrandire</h5>
                          </div>
                        </div>
                        <ul>
                          <div className="event-organizer-wrap col-lg-5 col-md-5">
                            <SRLWrapper>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="gallary-item">
                                    <Link to={PlanimetriaMattino}>
                                      <img
                                      src={PlanimetriaMattino}
                                      className="gallary-item-overlay planimetria"
                                      alt="Planimetria Mattino"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </SRLWrapper>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-container">
                <div className="event-info row">
                  <div className="row" >
                    <div className="custom-container-due" >
                      <div className="row justify-content-between" >
                        <div className="event-organizer-wrap">
                          <div className="category-title">
                            <h4>PLANIMETRIA SERA</h4>
                            <h5>Clicca per ingrandire</h5>
                          </div>
                        </div>
                        <ul className="row justify-content-between">
                          <div className="event-organizer-wrap col-lg-5 col-md-5">
                            <SRLWrapper>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="gallary-item">
                                    <Link to={PlanimetriaSera}>
                                      <img
                                      src={PlanimetriaSera}
                                      className="gallary-item-overlay planimetria"
                                      alt="Planimetria Sera"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </SRLWrapper>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                    <div className="custom-container-tre">
                    <div className="speakers-list">
                      <h5 className="ed-subtitle">Ristoranti convenzionati</h5>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="single-speaker">
                            <div className="speaker-image">
                              <img src={MainSeaker1} alt="Blog details" />
                            </div>
                            <div className="speaker-info">
                              <h6>Osteria Grotto del Nando</h6>
                              <strong>Ristorante/Grotto</strong>
                              <ul className="speaker-social-links">
                                <li>
                                  <Link onClick={this.scrollTop} to={"#"}>
                                    <i className="fab fa-facebook-f" />
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={this.scrollTop} to={"#"}>
                                    <i className="fab fa-instagram" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="single-speaker">
                            <div className="speaker-image">
                              <img src={MainSeaker2} alt="Blog details" />
                            </div>
                            <div className="speaker-info">
                              <h6>La Lampara</h6>
                              <strong>Ristorante pizzeria</strong>
                              <ul className="speaker-social-links">
                                <li>
                                  <Link onClick={this.scrollTop} to={"#"}>
                                    <i className="fab fa-facebook-f" />
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={this.scrollTop} to={"#"}>
                                    <i className="fab fa-instagram" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="single-speaker">
                            <div className="speaker-image">
                              <img src={MainSeaker3} alt="Blog details" />
                            </div>
                            <div className="speaker-info">
                              <h6>Locanda Marco</h6>
                              <strong>Ristorante</strong>
                              <ul className="speaker-social-links">
                                <li>
                                  <Link onClick={this.scrollTop} to={"#"}>
                                    <i className="fab fa-facebook-f" />
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={this.scrollTop} to={"#"}>
                                    <i className="fab fa-instagram" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>

                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-schedule"
                  role="tabpanel"
                  aria-labelledby="pills-schedule-tab">
                  <div className="comment-section">
                      <ul className="comments-list">
                        <li className="single-comment">
                          <div className="commentor-img">
                            <img src={Commentor1} alt="Blog details" />
                          </div>
                          <div className="comment-info">
                          <div className="commentor-info">
                              <div className="commentor-bio">
                                <h6 className="commentor-name">
                                  Intrattenimento
                                </h6>
                                <div className="comment-timing">
                                  <span className="comment-date">
                                    27 maggio 2023
                                  </span>{" "}
                                  <span className="comment-time">Dalle 09:30 alle 14:30</span>
                                </div>
                              </div>
                            </div>
                            <p>
                              Animazione per ragazzi e famiglie, con la presenza di Fabrizio Casati e la musica dei “Tri Per Du”
                            </p>
                          </div>
                        </li>
                        <li className="single-comment">
                          <div className="commentor-img">
                            <img src={Commentor2} alt="Blog details" />
                          </div>
                          <div className="comment-info">
                          <div className="commentor-info">
                              <div className="commentor-bio">
                                <h6 className="commentor-name">
                                  Intrattenimento
                                </h6>
                                <div className="comment-timing">
                                  <span className="comment-date">
                                    27 maggio 2023
                                  </span>{" "}
                                  <span className="comment-time">Dalle 12:00 alle 14:00</span>
                                </div>
                              </div>
                            </div>
                            <p>
                              Risottata preparata dallo Chef kiwaniano con il suo staff
                            </p>
                          </div>
                        </li>
                        <li className="single-comment">
                          <div className="commentor-img">
                            <img src={Commentor3} alt="Blog details" />
                          </div>
                          <div className="comment-info">
                          <div className="commentor-info">
                              <div className="commentor-bio">
                                <h6 className="commentor-name">
                                  Intrattenimento
                                </h6>
                                <div className="comment-timing">
                                  <span className="comment-date">
                                    27 maggio 2023
                                  </span>{" "}
                                  <span className="comment-time">Dalle 18:30 in poi</span>
                                </div>
                              </div>
                            </div>
                            <p>
                              Tartare a go go… 
                              Abbinamento cena e concerto in posti riservati a CHF 00.00
                            </p>
                          </div>
                        </li>
                        <li className="single-comment">
                          <div className="commentor-img">
                            <img src={Commentor4} alt="Blog details" />
                          </div>
                          <div className="comment-info">
                          <div className="commentor-info">
                              <div className="commentor-bio">
                                <h6 className="commentor-name">
                                  Concerto
                                </h6>
                                <div className="comment-timing">
                                  <span className="comment-date">
                                    27 maggio 2023
                                  </span>{" "}
                                  <span className="comment-time">Dalle 20:00</span>
                                </div>
                              </div>
                            </div>
                            <p>
                              E per finire in bellezza 
                              Sebalter in concerto
                            </p>
                          </div>
                        </li>
                      </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-comitato"
                  role="tabpanel"
                  aria-labelledby="pills-comitato-tab">
                  <div className="comment-section">
                      <ul className="comments-list">
                        <li className="single-comment">
                          <div className="comment-info">
                            {/* <div className="commentor-info"> */}
                              {/* <div className="commentor-bio"> */}
                                <ul>
                                  <li><p></p></li>
                                  <li><b>Marco Bertoli</b> - presidente</li>
                                  <li><b>Emilio Scossa-Baggi</b> - segretario </li>
                                  <li><b>Giorgio Bernasconi</b> – responsabile “Cabina di regia” con gli altri club di servizio – coordinazione  </li>
                                  <li><b>Graziano Gemetti</b> – chef risottata</li>
                                  <li><b>Stefano Rossi</b> – sous-chef risottata </li>
                                  <li><b>Giovanni Sciuchetti</b> – grafica – comunicazione – media </li>
                                  <li><b>Luca Castelli</b> – finance – sponsoring – business plan </li>
                                  <li><b>Katia Bogiani</b> – animazione famiglie</li>
                                  <li><b>Graziano Crugnola</b> – animazione famiglie </li>
                                  <li><b>(Vacante</b>) – “bambinoaiuta bambino” (domenica 28.05)</li>
                                  <li><b>(Vacante</b>) – walking – gara podistica (domenica 28.05)</li>
                                  <li><b>Daniele Bianchini</b> – serata spensierata </li>
                                  <li><b>Paolo Jelmini</b> – convenzioni</li>
                                  <li><b>Marco Marcionelli</b> – convenzioni – coordinazione – media</li>
                                  <li><b>Fabio Cagni</b> – gestione cassa</li>
                                </ul>
                              {/* </div> */}
                            {/* </div> */}
                          </div>
                        </li>
                      </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-gallary"
                  role="tabpanel"
                  aria-labelledby="pills-gallary-tab">
                  <div className="gallary-tab-content">
                  <SRLWrapper>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="gallary-item">
                        <img src={Gallery1} alt="Gallery1" />
                          <Link to={Gallery1}>
                            <img
                              src={Gallery1}
                              className="gallary-item-overlay"
                              alt="Gallery1"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gallary-item">
                          <img src={Gallery2} alt="Gallery2" />
                          <Link to={Gallery2}>
                            <img
                              src={Gallery2}
                              className="gallary-item-overlay"
                              alt="Gallery2"
                            />
                          </Link>
                        </div>
                        <div className="gallary-item">
                          <img src={Gallery3} alt="Gallery3" />
                          <Link to={Gallery3}>
                            <img
                              src={Gallery3}
                              className="gallary-item-overlay"
                              alt="Gallery3"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gallary-item">
                          <img src={Gallery4} alt="Gallery4" />
                          <Link to={Gallery4}>
                            <img
                              src={Gallery4}
                              className="gallary-item-overlay"
                              alt="Gallery4"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gallary-item">
                          <img src={Gallery5} alt="Gallery5" />
                          <Link to={Gallery5}>
                            <img
                              src={Gallery5}
                              className="gallary-item-overlay"
                              alt="Gallery5"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gallary-item">
                          <img src={Gallery6} alt="Gallery6" />
                          <Link to={Gallery6}>
                            <img
                              src={Gallery6}
                              className="gallary-item-overlay"
                              alt="GallaryIMAGE"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gallary-item">
                          <img src={Gallery7} alt="Gallery7" />
                          <Link to={Gallery7}>
                            <img
                              src={Gallery7}
                              className="gallary-item-overlay"
                              alt="GallaryIMAGE"
                            />
                          </Link>
                        </div>
                        <div className="gallary-item">
                          <img src={Gallery8} alt="Gallery8" />
                          <Link to={Gallery8}>
                            <img
                              src={Gallery8}
                              className="gallary-item-overlay"
                              alt="Gallery8"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gallary-item">
                          <img src={Gallery9} alt="Gallery9" />
                          <Link to={Gallery9}>
                            <img
                              src={Gallery9}
                              className="gallary-item-overlay"
                              alt="Gallery9"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gallary-item">
                          <img src={Gallery10} alt="Gallery10" />
                          <Link to={Gallery10}>
                            <img
                              src={Gallery10}
                              className="gallary-item-overlay"
                              alt="Gallery10"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="gallary-item">
                          <img src={Gallery11} alt="Gallery11" />
                          <Link to={Gallery11}>
                            <img
                              src={Gallery11}
                              className="gallary-item-overlay"
                              alt="GallaryIMAGE"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  
                    </SRLWrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EventDetailsLeftArea;

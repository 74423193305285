import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
// Swiper slider imported
import SwiperCore, {
  Navigation,
} from "swiper";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import WOW from 'wowjs';
import { Parallax, ParallaxBanner, ParallaxBannerLayer, ParallaxProvider } from 'react-scroll-parallax';
import '../../../assets/css/animate.css';
// image import
// import HeroIMG1 from "../../../assets/images/hero/hero-figure1.png";
// import Locandina from "../../../../assets/images/home/Locandina.jpg";
import HeroIMG2 from "../../../assets/images/shapes/hero-animi-blu.png";
import ImageShape from "../../../assets/images/shapes/hero-shape-blu.png";

import OrganizerIMG1 from "../../../assets/images/risottata/SPONSOR-Kiwanis.svg";


import RazzoSVG from "../../../assets/images/home/Razzo-rosso-svg.svg";
import Kiwanis from "../../../assets/images/home/SPONSOR-Kiwanis.svg";


import TikOcchiolino from "../../../assets/images/risottata/Tik-occhiolino.svg";
import TikYoutube from "../../../assets/images/risottata/Tik-youtube.svg";


// install Swiper modules
SwiperCore.use([ Navigation]);
class HeroArea extends Component {
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
    };
  }
  componentDidMount(){
      new WOW.WOW().init()
  }
  changeDatepickerHaldeller = (date) => {
    this.setState({ startDate: date });
  };
  render() {
    const startDate = this.state.startDate;
    const sliderInit = {
      slidesPerView: 1,
      loop: true,
      speed: 2400,
      effect: "slide",
      nav: "true",
      autoplay: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".hero-pagination",
        type: "custom",
        clickable: true,
        renderCustom: function (swiper, current, total) {
          function numberAppend(d) {
            return d < 10 ? "0" + d.toString() : d.toString();
          }
          return numberAppend(current);
        },
      },
    };
    return (
      <>
        <div className="main-slider-wrapper">

          {/* ===============  hero area start  =============== */}
          <div className="hero-area">   
            <div className="hero-shape">
              <img src={ImageShape} alt="HeroIMG" />
            </div>
            <div className="container">
              <div className="swiper-container hero-slider overflow-hidden">
                <div className="row align-items-center" >
                  <div className="col-lg-6 text-center" >
                    <div className="slide-figure position-relative d-lg-flex justify-content-center">
                      <div className="animated-shape">
                        <img src={HeroIMG2} alt="HeroIMG" />
                      </div>
                    </div>
                    <div className="slide-content" >
                    <ParallaxProvider>
                      <Parallax speed={-10}>
                        <img src={RazzoSVG} alt="Logo" className="razzo"/>
                      </Parallax>
                      <Parallax speed={-15}>
                        <img src={TikOcchiolino} alt="Logo" className="tik-banner-home" />
                      </Parallax>
                      <Parallax speed={-20}>
                        <img src={TikYoutube} alt="Logo" className="tik-banner-home-due" />
                      </Parallax>
                    </ParallaxProvider>
                      <h2 style={{ textAlign: "left" }}><span>TiK<br></br></span></h2>
                        <div className="breadcrumb-content"><h2 className="page-title title-home">Ti dà il benvenuto!<br></br>
                        Naviga con noi...</h2></div>
                      <div className="slider-btns">
                        <Link
                            onClick={this.scrollTop}
                          to={`${process.env.PUBLIC_URL}/risottata`}
                          className="primary-btn-fill">
                          Scopri di più
                        </Link>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="custom-container-home">
                    <p>
                      Un progetto promosso da <a href="http://kiwanis-bellinzona.ch/" target={"_blank"}>
                      <img src={OrganizerIMG1}  alt="Imgs" className="logo-promoter-small"/>
                      </a>
                    </p>
                  </div>
              </div>
            </div>
          </div>
          {/* ===============  hero area end  =============== */}
        </div>
      </>
    );
  }
}

export default HeroArea;

import React, { Component } from "react";
import { Link } from "react-router-dom";
class LayoutFooter extends Component {
  
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    
    return (
      <>
        {/* ===============  footer area start  =============== */}
        <div className="footer-outer pt-120 ">
          <div className="footer-area">
            <div className="container">
              <div className="footer-wrapper">
                <div className="row">
                  <div className="col-lg-2 col-md-6 col-sm-5 order-1">
                    <div className="footer-widget mt-0">
                      <h5 className="footer-widget-title"></h5>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-3">
                    <div className="footer-newslatter-wrapper">
                    <div className="footer-widget">
                      <h5 className="footer-widget-title">Contatti</h5>
                      <div className="footer-links">
                        <div className="link-list">
                          <div className="contact-icon">
                            <div className="event-d-sidebar">
                              <div className="category-title" style={{ color: 'white' }}>
                                <i className="bi bi-envelope-open" />
                                <h4>info@k-youth.ch</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-7 order-lg-3 order-2 ">
                    {/* <div className="footer-widget">
                      <h5 className="footer-widget-title">Contact</h5>
                      <div className="footer-links">
                        <ul className="link-list">
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-telephone-plus" />
                            </div>
                            <div className="contact-links">
                              <Link
                                onClick={this.scrollTop}
                                to={"tel:+17632275032"}>
                                +1 763-227-5032
                              </Link>
                              <Link
                                onClick={this.scrollTop}
                                to={"tel:+17632275032"}>
                                +1 763-227-5032
                              </Link>
                            </div>
                          </li>
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-envelope-open" />
                            </div>
                            <div className="contact-links">
                              <Link
                                onClick={this.scrollTop}
                                to={"mailto:info@example.com"}>
                                info@example.com
                              </Link>
                              <Link
                                onClick={this.scrollTop}
                                to={"mailto:support@example.com"}>
                                support@example.com
                              </Link>
                            </div>
                          </li>
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-geo-alt" />
                            </div>
                            <div className="contact-links">
                              <Link onClick={this.scrollTop} to={"#"}>
                                2752 Willison Street Eagan, United State
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="footer-bottom">
                <div className="row align-items-center">
                  <div className="col-lg-5  order-3 order-lg-1">
                    <div className="footer-copyright">
                      <p>
                        Copyright 2023 | Design By{" "}
                        <Link onClick={this.scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                          Domenico Serravalle
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 order-1 order-lg-2">
                    <div className="footer-logo">
                      <Link onClick={this.scrollTop} to={"#"}>
                        <img src="assets/images/logo-v2.png" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 order-2 order-lg-3">
                    <ul className="d-flex footer-social-links justify-content-lg-end justify-content-center">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                          &nbsp; Pagina Facebook
                        </Link>
                      </li>
                      {/* <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  footer area end  =============== */}
      </>
    );
  }
}

export default LayoutFooter;

import React, { Component } from "react";
import CountUp from "react-countup";
// images import
import icon2IMG from '../../../assets/images/icons/gold-fa2.png';
import icon4IMG from '../../../assets/images/icons/people.png';
import icon3IMG from '../../../assets/images/icons/schedule.png';
import icon1IMG from '../../../assets/images/icons/speaker.png';

import moneyIcon from "../../../assets/images/about/money.png"
import calendarIcon from "../../../assets/images/about/calendar.png"
import founderIcon from "../../../assets/images/about/co-founder.png"

class AchivementArea extends Component {
  render() {
    return (
      <>
        {/* ===============  achievement area start  =============== */}
        <div className="achievement-area pt-120">
          <div className="achievement-overlay">
            <div className="container">
              <div className="row">
                <div className="col-sm">
                  <div className="single-achievement">
                    <div className="achievement-icon">
                      <img src={moneyIcon} alt="about icon" style={{ color: "yellow" }} />
                    </div>
                    <h2>
                      <CountUp
                        start={0}
                        end={500000}
                        duration={6.75}
                        className="number"></CountUp>
                      &nbsp; CHF
                    </h2>
                    <h5>Donati in opere sociali e aiuti finaziari</h5>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="single-achievement">
                    <div className="achievement-icon">
                      <img src={calendarIcon} alt="about icon " />
                    </div>
                    <h2>
                      <CountUp
                        start={2023}
                        end={1985}
                        duration={4.75}
                        className="number"></CountUp>
                      
                    </h2>
                    <h5>Anno di fondazione</h5>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="single-achievement">
                    <div className="achievement-icon">
                      <img src={founderIcon} alt="about icon " />
                    </div>
                    <h2>
                      <CountUp
                        start={0}
                        end={50}
                        duration={3}
                        className="number"></CountUp>
                      
                    </h2>
                    <h5>Soci tra Attivi e Senior</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  achievement area end  =============== */}
      </>
    );
  }
}

export default AchivementArea;
